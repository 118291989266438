.space {
    text-align: center;
}

.space .cover {
    width: 100%;
}

.container {
    background: url('../images/bg/background-texture.jpg') no-repeat 0 0;
    background-size: 100%;
}

.container .content {
    padding: 180px 0;
}

.content p {
    font-size: 26px;
    letter-spacing: 2px;
}

.space-slider {
    padding: 35px 0 118px;
}

.space-slider .slick-prev:before,
.space-slider .slick-next:before {
    font-size: 13px;
}

@media (min-width: 721px) {
    .space-slider {
        padding: 0;
    }
}
