.greeting {
    color: #3d3935;
    text-align: center;
    padding: 0;
    margin: 0;
}

.cover {
    width: 100%;
}

.cover h1 {
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 2px;
}

.greeting .logo {
    width: 35px;
    margin-bottom: 24px;
}

.home-speciality {
    padding: 80px 0 80px;
}

.home-speciality li {
    font-weight: 300;
}

.home-speciality .cover {
    top: 372px;
}

.home-department {
    color: #eaeada;
}

.home-department .content {
    padding: 20px 37px 5px;
}

.home-department .cover {
    top: 0;
}

.home-department .btn {
    color: #eaeada;
    border-color: #eaeada;
}

.dep-implant,
.dep-orthodontia,
.dep-child,
.dep-beauty,
.dep-gum,
.dep-neuro,
.dep-wisdom,
.dep-jaw,
.dep-denture,
.dep-preservation,
.dep-digital,
.dep-decay {
    display: block;
    background: transparent url('../images/icon-departments.png') no-repeat 0 0;
    background-size: auto 100%;
    text-indent: -99999em;
    margin: 7px auto;
}

.home-space .cover {
    top: 20px;
}

@media (min-width: 721px) {
    .home-speciality {
        padding: 0;
    }

    .home-speciality .content {
        padding-top: 0;
    }

    .dep-implant,
    .dep-orthodontia,
    .dep-child,
    .dep-beauty,
    .dep-gum,
    .dep-neuro,
    .dep-wisdom,
    .dep-jaw,
    .dep-denture,
    .dep-preservation,
    .dep-digital,
    .dep-decay {
        width: 140px;
        height: 172px;
    }

    .dep-implant {
        background-position-x: 4px;
    }

    .dep-orthodontia {
        background-position-x: -134px;
    }

    .dep-child {
        background-position-x: -272px;
    }

    .dep-beauty {
        background-position-x: -416px;
    }

    .dep-gum {
        background-position-x: -550px;
    }

    .dep-neuro {
        background-position-x: -690px;
    }

    .dep-wisdom {
        background-position-x: -830px;
    }

    .dep-jaw {
        background-position-x: -970px;
    }

    .dep-denture {
        background-position-x: -1110px;
    }

    .dep-preservation {
        background-position-x: -1252px;
    }

    .dep-digital {
        background-position-x: -1394px;
    }

    .dep-decay {
        background-position-x: -1536px;
    }

    .home-department {
        color: #fff;
    }

    .home-department ul {
        width: 1214px;
        display: flex;
        flex-wrap: wrap;
        padding: 97px 0 83px;
        margin: 0 auto;
    }

    .home-department li {
        width: 200px;
        height: 200px;
        border-top: 2px solid #b7b8b0;
        border-right: 2px solid #b7b8b0;
    }

    .home-department li:nth-child(-n+6) {
        border-top: 0;
    }

    .home-department li:nth-child(6n+1) {
        border-left: 2px solid #b7b8b0;
    }
}

@media (max-width: 720px) {
    .dep-implant,
    .dep-orthodontia,
    .dep-child,
    .dep-beauty,
    .dep-gum,
    .dep-neuro,
    .dep-wisdom,
    .dep-jaw,
    .dep-denture,
    .dep-preservation,
    .dep-digital,
    .dep-decay {
        width: 70px;
        height: 86px;
    }

    .dep-orthodontia {
        background-position-x: -66px;
    }

    .dep-child {
        background-position-x: -136px;
    }

    .dep-beauty {
        background-position-x: -210px;
    }

    .dep-gum {
        background-position-x: -276px;
    }

    .dep-neuro {
        background-position-x: -346px;
    }

    .dep-wisdom {
        background-position-x: -416px;
    }

    .dep-jaw {
        background-position-x: -484px;
    }

    .dep-denture {
        background-position-x: -555px;
    }

    .dep-preservation {
        background-position-x: -626px;
    }

    .dep-digital {
        background-position-x: -696px;
    }

    .dep-decay {
        background-position-x: -768px;
    }

    .home-department ul {
        display: flex;
        flex-wrap: wrap;
        padding: 50px 30px;
    }

    .home-department li {
        flex: 1 1 33%;
        width: 100px;
        height: 100px;
        border-top: 1px solid #b7b8b0;
        border-left: 1px solid #b7b8b0;
    }

    .home-department li:nth-child(-n+3) {
        border-top: 0;
    }

    .home-department li:nth-child(3n+1) {
        border-left: 0;
    }
}
