.detail {
    position: relative;
    background-color: #f7f7f1;
    margin-top: 22px;
}

.detail .line-v {
    margin-bottom: 15px;
}

.detail .dummy {
    position: relative;
}

.detail .cover {
    top: 35px;
    left: 25px;
    bottom: unset;
    color: #3d3935;
}

.detail .cover.bottom {
    position: absolute;
    top: unset;
    bottom: 22px;
}

.detail .cover.bottom p {
    color: #eaeada;
    font-size: 10px;
    padding-right: 25px;
}

.detail .indent li::before {
    content: '-';
    padding-right: 4px;
}

.detail .indent li p {
    font-size: 10px;
    font-weight: 300;
    line-height: 170%;
    padding-bottom: 10px;
}

.content p {
    font-size: 10px;
    padding: 0 3px 15px;
}

.content {
    padding-top: 20px;
}

.content .box {
    margin: 15px auto 25px;
}

.content .box span {
    line-height: 160%;
    font-size: 10px;
}

.content .box p {
    font-size: 10px;
    line-height: 160%;
    padding-top: 10px;
}

.slide-item {
    flex: 0 0 auto;
    width: 125px;
    height: 265px;
    margin-left: 7px;
}

.slide-item img {
    width: 100%;
}

.float-right {
    width: 136px;
    float: right;
    margin-left: 14px;
}
