.hours {
    padding: 45px 20px 20px;
}

.hours table {
    width: 100%;
}

.hours tr *:first-child {
    width: 125px;
}

.hours tr *:last-child {
    width: 195px;
}

.hours th,
.hours td {
    padding: 10px 0 9px;
}

.hours .double {
    height: auto;
    padding: 4px 0 0;
}

.hours tr *:last-child.double {
}

.hours table td:last-child small {
    position: relative;
    top: -8px;
}

.hours p {
    margin: 15px 0 22px;
}

.locations {
    padding: 45px 20px 20px;
}

.locations img {
    margin: 12px 0 70px;
}

.info-bus,
.info-subway {
    margin-bottom: 22px;
}

.info-bus dl,
.info-subway dl {
    padding: 7px 0 0;
}

.info-bus dt,
.info-subway dt {
    position: absolute;
    display: inline-block;
    min-width: 36px;
    color: #f7f7f1;
    background-color: #64675d;
    font-weight: lighter;
    text-align: center;
    border-radius: 5px;
    padding: 2px 0 3px;
}

.info-bus dd,
.info-subway dd {
    display: inline-block;
    padding-bottom: unset;
    margin: 2px 0 0 42px;
}

.info-bus ul,
.info-bus li {
    display: inline;
}

.info-bus li::before {
    content: ' / ';
}

.info-bus li:first-child::before {
    content: '';
}

@media (min-width: 721px) {
    .hours {
        background: url('../images/bg/hours.jpg') no-repeat;
        padding: 330px 155px 300px;
    }

    section.hours h2,
    section.locations h2 {
        color: #64675d;
        font-size: 36px;
    }

    section.hours p {
        color: #64675d;
        font-size: 24px;
    }

    section.hours table {
        width: auto;
        text-align: left;
        border-collapse: separate;
        margin: 0;
    }

    section.hours table th,
    section.hours table td {
        font-size: 24px;
        border: 0;
        border-bottom: 1px solid #64675d;
    }

    section.hours table thead th,
    section.hours table thead td {
        border-top: 1px solid #64675d;
    }

    section.hours table tr th:first-child,
    section.hours table tr td:first-child {
        width: 288px;
        border-right: 1px solid #64675d;
    }

    section.hours table tr td:last-child {
        width: 420px;
    }

    section.hours small {
        font-size: 15px;
    }

    .locations {
        min-height: 842px;
        padding: 100px 160px;
    }

    .locations h3 {
        font-size: 24px;
        padding-top: 0;
        padding-bottom: 20px;
    }

    section.locations p {
        font-size: 20px;
    }

    .map {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #fff;
        padding: 150px 180px;
    }

    .map img {
        width: 780px;
    }

    .locations .btn {
        margin-left: 35px;
    }

    section.locations dl {
        width: 475px;
        padding: 0 0 20px;
    }

    section.locations dt {
        min-width: 62px;
        font-size: 20px;
    }

    section.locations dd {
        font-size: 20px;
        font-weight: normal;
        margin-left: 80px;
    }

    section.locations li {
        font-size: 20px;
    }
}
