.bi {
    text-align: center;
    margin-bottom: -4px;
}

.bi .cover {
    top: 405px;
    width: 100%;
}

.each-slide {
    position: relative;
    width: 100%;
}

.bi-slide-01 .cover {
    top: 353px;
}

.bi-slide-02 .cover {
    top: 442px;
}

.cover h2 {
    text-align: center;
}

.slogan {
    background: transparent url('../images/bg/slogan.png') no-repeat;
    background-size: 100% auto;
    padding: 15px 20px 20px;
}

.logo-text {
    width: 75px;
    margin: 0;
}

.slogan p {
    color: #64675d;
    font-weight: normal;
    padding-top: 25px;
}

.logo {
    width: 119px;
    margin: 0 auto 24px;
}

.promise-message {
    padding: 66px 20px 80px;
}

.promise-message .cover {
    top: 200px;
    left: 38px;
}

.promise-message h2 {
    text-align: left;
}

.promise .hg {
    letter-spacing: 2px;
    padding-bottom: 20px;
}

.promise img {
    margin-bottom: 15px;
}

@media (min-width: 721px) {
    .bi .cover {
        top: 513px;
    }

    .bi-slide-01 .cover {
        top: 483px;
    }

    .bi-slide-02 .cover {
        top: 603px;
    }

    .cover-image {
        width: 100%;
    }

    .cover h2 {
        margin: 0 auto 67px;
    }

    .logo {
        width: 228px;
        margin: 0 auto 67px;
    }

    .promise-message {
        color: #64675d;
        text-align: center;
        padding: 0;
    }

    .promise-message .cover {
        width: 100%;
        top: 470px;
        left: 0;
        margin: 0 auto;
    }

    .promise-message .cover h2 {
        text-align: center;
    }

    .announce {
        color: #64675d;
    }

    .announce .cover {
        top: 180px;
        left: 155px;
        text-align: left;
    }

    .announce .cover h2 {
        position: relative;
        //left: -5px;
        text-align: left;
        margin: 0;
    }

    .promise {
        color: #64675d;
        background-color: #fff;
    }

    .promise h3 {
        font-size: 36px;
        letter-spacing: 2px;
        text-align: left;
        padding-bottom: 40px;
    }

    .promise .container p {
        font-size: 24px;
    }

    .promise .overview {
        position: absolute;
    }

    .promise .desc {
        width: 865px;
        padding: 0;
        margin-left: 764px;
    }

    .promise .desc img {
        width: 866px;
    }

    .promise .desc dt,
    .promise .desc dd p {
        font-size: 22px;
        font-weight: normal;
    }

    .promise .desc dd p {
        position: relative;
        top: -33px;
        left: 50px;
    }
}
