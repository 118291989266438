.breadcrumb {
    background-color: #fff;
    text-align: center;
    padding: 21px 0;
}

.breadcrumb li {
    display: inline-block;
    padding: 0 4px;
}

.breadcrumb a {
    color: #64675d;
    font-size: 10px;
    text-decoration: none;
}

.active {
    text-decoration: underline !important;
}

@media (min-width: 721px) {
    .breadcrumb {
        display: none;
    }
}
