.footer {
    position: relative;
    text-align: left;
    color: #eaeada;
    background-color: #3d3935;
    margin: 0;
}

@media (min-width: 721px) {
    .footer {
        padding: 54px 136px 23px;
    }

    .logo {
    }

    .title {
        display: none;
    }

    .info {
        position: absolute;
        top: 52px;
        left: 442px;
        padding-bottom: 23px;
    }

    .info li,
    .info li a {
        color: #eaeada;
        font-size: 14px;
        font-weight: normal;
        line-height: 170%;
        letter-spacing: 1px;
        text-decoration: none;
    }

    .contact {
        position: absolute;
        top: 52px;
        right: 137px;
        text-align: center;
    }

    .contact li:last-child {
        padding-top: 37px;
    }

    .contact .phone {
        display: block;
        color: #eaeada;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 2px;
        text-decoration: none;
    }

    .contact .ask {
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 1px;
    }

    .contact .kakao {
        font-size: 10px;
        font-weight: 300;
        line-height: 215%;
        letter-spacing: 1px;
    }

    .contact-mobile {
        display: none;
    }

    .emblem {
        display: none;
    }
}

@media (max-width: 720px) {
    .footer {
        padding: 25px 20px 63px;
    }

    .info {
        font-size: 10px;
        font-style: normal;
    }

    h2.title {
        font-size: 16px;
        padding: 0;
        margin: 0;
    }

    .title span {
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 2px;
    }

    .logo {
        display: none;
    }

    .contact-mobile,
    .info {
        list-style: none;
        padding: 10px 0 0;
        margin: 0;
    }

    .contact-mobile li,
    .contact-mobile li * {
        font-size: 12px !important;
    }

    .contact {
        display: none;
    }

    .footer li,
    .footer li * {
        color: #eaeada;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 180%;
        letter-spacing: 1px;
        text-decoration: none;
        text-align: left;
    }

    .contact li,
    .contact li * {
        font-size: 12px;
    }

    .emblem {
        position: absolute;
        right: 28px;
        bottom: 25px;
        width: 41px;
        height: 41px;
    }
}
