#root {
    width: 100%;
}

.App {
    width: 100%;
}

h1 {
    font-size: 24px;
    font-weight: normal;
    letter-spacing: 2px;
    margin-bottom: 15px;
}

h1 strong {
    font-size: 24px;
    font-weight: normal;
}

.App h2 {
    font-size: 24px;
}

.App h3 {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 2px;
}

.App h3.line-v {
    letter-spacing: 0;
    border-left: 1px solid #b7b8b0;
    padding-left: 4px;
}

.App h3.line-v.dark {
    color: #eaeada;
    border-color: #eaeada;
}

.App small {
    font-size: 10px;
    font-weight: 300;
}

.Content {
    background-color: #f7f7f1;
}

.btn {
    display: inline-block;
    width: 80px;
    color: #80807b;
    font-size: 12px;
    line-height: 100%;
    text-decoration: none;
    text-align: center;
    padding: 7px 10px 6px;
    border: 1px solid #80807b;
    border-radius: 20px;
}

.btn-fill {
    color: #eaeada;
    background-color: #80807b;
}

.btn-group {
    padding: 20px 0;
}

.btn-group a {
    margin-left: 8px;
}

.btn-group a:first-child {
    margin-left: 0;
}

section {
    position: relative;
    color: #3d3935;
}

section img {
    margin: 0 auto;
}

section .content {
    position: relative;
    padding: 27px 37px 20px 37px;
}

section .content .box {
    border: 1px solid #b7b8b0;
    padding: 15px;
}

section .content .box p {
    padding: 0;
}

section .content .slider {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

section .content .slide-item {
    flex: 0 0 auto;
}

section .content .slide-item:first-child {
    margin-left: 0;
}

section.white {
    color: #64675d;
    background-color: #fff;
}

section .cover {
    position: absolute;
    top: 442px;
    margin: 0 auto;
}

section h2 {
    display: block;
    font-size: 24px;
    font-weight: normal;
    text-align: left;
    padding-bottom: 24px;
}

.App section h2.line-hg {
}

.App section h2.line-hg:after {
    content: ' ';
    display: block;
    height: 100px;
    border-left: 1px solid #64675d;
    margin: 10px 5px 0;
}

section em {
    display: block;
    font-size: 12px;
    font-style: normal;
}

section p {
    font-size: 12px;
    font-weight: 300;
    line-height: 150%;
}

section p.hg {
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    letter-spacing: normal;
}

section dl {
    font-size: 12px;
    font-weight: 300;
    line-height: 150%;
    text-decoration: none;
}

section dt {
    padding-top: 20px;
}

section dd {
    padding-bottom: 20px;
}

section dd .btn {
    margin-top: 15px;
}

section ul.indent {
    display: table;
}

section ul.indent li {
    display: table-row;
}

section ul.indent li::before {
    content: '·';
    display: table-cell;
}

section ul.indent li p:last-child {
    padding-bottom: 4px;
}

.App .slick-prev {
    left: 15px;
    z-index: 100;
}

.App .slick-next {
    right: 15px;
    z-index: 100;
}

.App .slick-dots {
    bottom: 16px;
}

.App .slick-dots li {
    margin: 0;
}

@media (min-width: 721px) {
    .App .mobile {
        display: none;
    }

    .App {
        width: 1920px;
    }

    .App section h1 {
        font-size: 60px;
        letter-spacing: 3px;
    }

    .App section h2 {
        font-size: 60px;
        font-weight: normal;
    }

    .App section em {
        font-size: 30px;
        font-weight: normal;
    }

    .App section p,
    .App section span,
    .App section li {
        font-size: 30px;
        font-weight: normal;
    }

    section .cover {
        top: 706px;
    }

    section .content {
        padding: 162px 157px 0;
    }

    .slick-slider .slick-prev:before,
    .slick-slider .slick-next:before {
        font-size: 42px;
    }

    .App .slick-dots {
        bottom: 35px;
    }

    .App .slick-dots li {
        width: 23px;
    }

    .App .slick-dots li button::before {
        font-size: 17px;
    }

    .btn {
        color: #3d3935;
        width: 130px;
        font-size: 20px;
        font-weight: 300;
        border: 1px solid #3d3935;
        border-radius: 24px;
        padding: 12px 10px;
    }

    .btn-fill {
        color: #3d3935;
        background-color: transparent;
    }

    .btn-group {
        padding: 45px 0;
    }
}

@media (max-width: 720px) {
    .App .pc {
        display: none;
    }
}
