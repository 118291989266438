.booking {
    position: fixed;
    top: 100px;
    right: 20px;
    display: block;
    z-index: 10002;
    list-style: none;
    padding: 0;
    margin: 0;
}

.icon-naver,
.icon-kakao,
.icon-phone {
    display: block;
    width: 36px;
    height: 36px;
    text-indent: -99999em;
    border: 0;
    padding: 0;
    margin: 0 0 6px 0;
    background: rgb(86,86,79) none no-repeat center center;
    background-color: rgba(86,86,79, 0.5);
    background-size: 18px;
    border-radius: 100%;
}

.icon-naver {
    background-image: url('../images/icon-naver.png');
}

.icon-kakao {
    background-image: url('../images/icon-kakao.png');
}

.icon-phone {
    background-image: url('../images/icon-phone.png');
}

@media (min-width: 721px) {
    .booking {
        top: 134px;
        right: 68px;
    }

    .icon-naver,
    .icon-kakao,
    .icon-phone {
        width: 73px;
        height: 73px;
        background-size: 36px;
        margin-bottom: 13px;
    }
}
