.header {
    position: fixed;
    z-index: 10000;
}

.btn-drawer,
.btn-close {
    position: fixed;
    display: block;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    border: 0;
    text-indent: -99999em;
}

.btn-drawer {
    background-image: url('../images/btn-drawer.png');
}

.btn-close {
    z-index: 10003;
}

@media (min-width: 721px) {
    .header {
        width: 1920px;
        text-align: center;
    }

    .header a {
        text-align: center;
    }

    .title,
    .bright {
        display: block;
        background: url('../images/dental-clinic-pc.png') no-repeat center;
        background-size: 100% auto;
        width: 170px;
        height: 68px;
        text-indent: -99999em;
        z-index: 10002;
        margin: 57px auto 0;
    }

    .btn-drawer {
        width: 75px;
        height: 76px;
        top: 48px;
        left: 67px;
    }

    .btn-close {
        width: 31px;
        height: 31px;
        top: 61px;
        left: 612px;
        background-image: url('../images/icon-close.png');
    }
}

@media (max-width: 720px) {
    .title,
    .bright {
        position: fixed;
        top: 25px;
        left: 25px;
        display: block;
        background: url('../images/dental-clinic.png') no-repeat center;
        background-size: 100% auto;
        width: 80px;
        height: 31px;
        text-indent: -99999em;
        z-index: 10002;
    }

    .bright {
        background-image: url('../images/dental-clinic-bright.png');
    }

    .btn-drawer,
    .btn-close {
        top: 20px;
        right: 20px;
        width: 37px;
        height: 38px;
    }

    .btn-close {
        background-image: url('../images/btn-drawer-close.png');
    }
}
