.navigation {
    position: absolute;
    z-index: 10001;
}

.sidebar {
    position: fixed;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    color: #eaeada;
    background-color: #80807b;
    background-color: rgba(128, 128, 123, 0.75);
    background-repeat: no-repeat;
    background-position: center bottom 25px;
    background-size: 36px 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
}

.sidebar a {
    color: #eaeada;
    text-decoration: none;
}

.main {
    padding: 82px 0 0 27px;
}

.main li,
.main li a,
.main li span {
    font-size: 20px;
    font-weight: normal;
    line-height: 100%;
    letter-spacing: 2px;
}

.main li {
    padding: 0 0 24px;
}

.inactive {
    display: none;
}

.sub {
    display: block;
    padding: 2px 0 0 2px;
}

.sub li {
    padding: 12px 0 0;
}

.sub li a {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 4px;
}

@media (min-width: 721px) {
    .sidebar {
        width: 761px;
        text-align: left;
    }

    .main {
        padding: 144px 0 0 87px;
    }

    .main li {
        padding: 0 0 40px;
    }

    .main li,
    .main li a,
    .main li span {
        font-size: 30px;
    }

    .sub li {
        padding: 30px 0 0;
    }

    .sub li,
    .sub li a,
    .sub li span {
        font-size: 24px;
    }
}

@media (max-width: 720px) {
    .sidebar {
        background-image: url('../images/drawer-emblem.png');
    }
}
