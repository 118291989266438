.speciality section {
}

.speciality .cover {
    color: #eaeada;
    top: 450px;
    left: 37px;
}

.speciality .cover li {
    padding-bottom: 10px;
}

.btn {
    color: #eaeada;
    text-decoration: none;
    border-color: #eaeada;
    padding: 5px 15px 6px;
}

.speciality .content {
    padding: 35px 20px 22px;
}

.speciality .content.narrow {
    padding: 0 30px 20px;
}

.speciality .line-hg {
    padding: 0 8px;
}

.speciality .hg {
    margin: 18px 0;
}

.cooperation {
    color: #fff;
    font-weight: 300;
    min-height: 134px;
    background: #fff url('../images/cooperation.png') no-repeat bottom;
    background-size: 100%;
    text-align: center;
    padding: 106px 0 0;
}

.speciality-prevention .content,
.speciality-premiere .content {
    padding-top: 0;
    padding-bottom: 0;
}

.speciality-prevention .content:first-child,
.speciality-premiere .content:first-child {
    padding-top: 50px;
    padding-bottom: 0;
}

.speciality-prevention .content:last-child,
.speciality-premiere .content:last-child {
    padding-bottom: 38px;
}

.speciality-prevention .cover {
    color: #f7f7f1;
    top: unset;
    bottom: 16px;
}

.speciality-prevention .cover p {
    font-size: 10px;
}

.speciality-prevention .cover p strong {
    font-weight: normal;
}

@media (min-width: 721px) {
    .speciality .cover {
        color: #3d3935;
        top: 535px;
        left: 158px;
    }

    .speciality .btn {
        color: #3d3935;
        border-color: #3d3935;
    }

    .speciality .line-hg {
        font-size: 40px;
        line-height: 128%;
        letter-spacing: 2px;
        padding: 0;
    }

    .speciality .content .line-hg::after {
        height: 201px;
        border-left: 3px solid #b7b8b0;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .speciality dt,
    .speciality dd,
    .speciality dd p {
        font-size: 20px;
        font-weight: normal;
        line-height: 160%;
        letter-spacing: 1px;
    }

    .band {
        position: relative;
    }

    .band p {
        position: absolute;
        top: 300px;
        width: 100%;
        color: #fff;
        font-size: 40px;
        text-align: center;
    }

    .speciality .professional {
        background-color: transparent;
        padding: 213px 161px;
    }

    .speciality .content {
        padding: 0;
    }

    .speciality .content.narrow {
        padding: 0;
    }

    .speciality .professional dl {
        width: 560px;
    }

    .speciality .professional img {
        position: absolute;
        top: -280px;
        right: 0;
        width: 818px;
    }

    .speciality .speciality-prevention,
    .speciality .speciality-premiere {
        background-color: transparent;
        padding: 213px 161px;
    }

    .speciality .speciality-prevention .line-hg,
    .speciality .speciality-premiere .line-hg {
        position: absolute;
    }

    .speciality .speciality-prevention dl,
    .speciality .speciality-premiere dl {
        width: 868px;
        padding-left: 600px;
    }

    .speciality .speciality-prevention .thumb {
        padding: 0;
    }

    .speciality .speciality-prevention dt {
        position: absolute;
        padding-top: 40px;
    }

    .speciality .speciality-prevention dd {
        padding: 40px 0 180px 50px;
    }

    .speciality .speciality-premiere .narrow img {
        display: inline-block;
        width: 328px;
        height: 345px;
        padding-left: 596px;
        padding-bottom: 95px;
    }

    .speciality .speciality-premiere dl {
        position: absolute;
        top: 0;
        left: 378px;
        width: 460px;
    }

    .speciality .speciality-premiere dt {
        font-size: 36px;
        line-height: 140%;
        letter-spacing: 2px;
        padding-top: 0;
        padding-bottom: 30px;
    }
}

@media (max-width: 720px) {
    .thumb {
        display: none;
    }
}
