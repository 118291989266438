@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('../SpoqaHanSansNeo/SpoqaHanSansNeo.css');

*, body {
    font-family: 'Spoqa Han Sans Neo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
}

body {
    width: 100%;
    background-color: #3d3935;
}

ul {
    list-style: none;
}

img {
    display: block;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

small {
    font-size: 10px;
}

sub {
    position: relative;
    top: -4px;
    font-size: 10px;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    margin: 0 auto;
}

table th, table td {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 1px;
    text-align: center;
    min-height: 31px;
    border-collapse: collapse;
    border: 1px solid #64675d;
}

table th {
    font-weight: normal;
}

table td {
    font-weight: 300;
}

table tr *:first-child {
    border-left: 0;
}

table tr *:last-child {
    border-right: 0;
}

@media (max-width: 720px) {
    img {
        width: 100%;
    }
}
