.welcome .cover {
    top: 116px;
    left: 20px;
}

.welcome p {
    padding-bottom: 30px;
}

.signature {
    position: relative;
    top: -46px;
    left: 64px;
    width: 60px;
    margin: 0;
}

.mohw {
    color: #eaeada;
    font-size: 12px;
    font-weight: 300;
    line-height: 125%;
    text-align: center;
    background: url('../images/bg/team-pc-mohw.jpg') no-repeat center;
    background-size: 100%;
    padding: 38px 0;
}

.mohw img {
    display: inline;
    width: 93px;
    margin: 0 auto 11px;
}

.introduce {
    color: #3d3935;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    padding: 40px 0;
}

.history .content {
    padding: 22px 22px 40px;
}

.history p {
    color: #3d3935;
}

.history .english {
    color: #64675d;
    font-size: 10px;
}

@media (min-width: 721px) {
    .welcome {
        color: #64675d;
    }

    .welcome .cover {
        top: 240px;
        left: 200px;
    }

    .welcome .cover h2 {
        font-size: 48px;
        letter-spacing: 3px;
    }

    .welcome .cover p {
        font-size: 24px;
        font-weight: 300;
        line-height: 180%;
        letter-spacing: 2px;
    }

    .signature {
        top: -108px;
        left: 248px;
        width: 193px;
        margin: 0;
    }

    .mohw {
        color: #fff;
        height: 357px;
        padding: 280px 0 0;
    }

    .mohw img {
        width: 323px;
        filter: brightness(0) invert(1);
        margin: 0 auto 32px;
    }

    .mohw p {

        font-size: 30px;
    }

    .container {
        position: relative;
    }

    .members {
        background-color: #fff;
        padding: 0;
    }

    .members .content {
        padding: 0 0 0 1060px;
    }

    .members .content:first-child {
        padding: 0;
    }

    .members .introduce {
        padding: 148px 0;
    }

    .members .content em {
        font-size: 24px;
    }

    .members .content h2 {
        font-size: 48px;
        padding-bottom: 67px;
    }

    .profile {
        position: absolute;
        top: 334px;
        left: 200px;
        width: 766px;
        z-index: 2;
    }

    .history .content {
        padding: 0 0 220px 1060px;
    }

    .history .content li,
    .history .content p {
        font-size: 18px;
        line-height: 200%;
    }

    .history .content p.english {
        color: #64675d;
        font-size: 16px;
    }
}
